<template>
  <div class="bug_box">
    <div class="chk">
      <label :for="'boxitemidtrue'+index" class='bug_label'>
          <input type="radio"  :disabled="DataConfirm?.length > 0" :value="1"  v-model="boxValue">
          <p>Согласен</p>
      </label>
      <label :for="'boxitemidfalse'+index" class='bug_label'>
          <input type="radio"  :disabled="DataConfirm?.length > 0" :value="0"  v-model="boxValue">
          <p>Не согласен</p>
      </label>
    </div>
    <h2>{{item.Print_Title}}</h2>
    <b>Описание услуги:</b>
    <p>{{item.Print_Info}}</p>
    <p></p>
    <p>Стоимость услуги: {{item.Cost}}</p>
    <p>{{item.Print_Discription}}</p>
  </div>
</template>

<script>

export default {
  name: 'agreementBoxesItem',
  props:{
    index:{
      type:Number,
      default() {
        return 0;
      }
    },
    item:{
      type: Object ,
      default(){
       return {}
      }
    },
    DataConfirm:{
      type:String,
      default:''
    },
  },
  data(){
    return {
      boxValue:0
    }
  },
  mounted(){
    this.boxValue =  this.item.isActive;
  }
};
</script>

